import { Injectable, signal } from '@angular/core';
import type { ToastPosition } from '@vermeer-corp/it-ng-components/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastStateService {
  showCloseButton = signal<boolean>(true);
  expandByDefault= signal<boolean>(false);
  renderToastContainer = signal<boolean>(false);
  position = signal<ToastPosition>('top-right');
}
